















































































































































































































import {DateTime} from 'luxon';
// @ts-ignore
import DatetimePicker from 'vuetify-datetime-picker';
import {Component, Prop, Watch} from 'vue-property-decorator';
import StepBaseComponent from '@/components/shipArrival/create/steps/StepBaseComponent.vue';
import {validationMixin} from 'vuelidate';
import {required, minValue} from 'vuelidate/lib/validators';
import Voyage from '@/models/Voyage.model';
import {harborStoreGetter, harborStoreActions} from "@/store/harbor.store";
import {namespace} from "vuex-class";
import Harbor from "@/models/Harbor";

const HarborStore = namespace('harbor');

@Component({
  mixins: [validationMixin],
  validations: {
    validationObject: {
      contactInformation: {required},
      voyageNoImport: {required},
      voyageNoExport: {required},
      eta: {required},
      ets: {required},
      trade: {required},
      draughtIn: {required, minValue: minValue(1)},
      draughtOut: {required, minValue: minValue(1)}
    }
  }
})
export default class Step2Component extends StepBaseComponent {

  @HarborStore.Action(harborStoreActions.GET_ALL)
  public loadHarbors!: () => Promise<Harbor[]>;

  @HarborStore.Getter(harborStoreGetter.LIST_ALL)
  public _harbors!: Harbor[];

  @Prop({default: false})
  public isLoading!: boolean;

  public isSubmitted: boolean = false;

  private selectedLastPorts: Harbor[] = [];
  private selectedNextPorts: Harbor[] =[];
  /**
   * Mirror object to handle validation correclty (otherwise there can be a reference error)
   */
  public validationObject: Partial<Voyage> = {
    contactInformation: '',
    voyageNoImport: '',
    voyageNoExport: '',
    eta: '',
    ets: '',
    trade: '',
    lastPorts: [],
    nextPorts: [],
    draughtIn: undefined,
    draughtOut: undefined
  }

  private get harbors() {
    return this._harbors;
  }

  public get etaTextFieldProps(): any {
    return {
      outlined: true,
      dense: true,
      'error-messages': this.identifyErrorMessage(this.$v.validationObject.eta!)
    }
  }

  public get etsTextFieldProps(): any {
    return {
      outlined: true,
      dense: true,
      'error-messages': this.identifyErrorMessage(this.$v.validationObject.ets!)
    }
  }

  public timeProps = {
    format: '24hr',
  };

  get etsDatePickerProps() {
    if ((this.validationObject as Voyage).eta) {
      const date = DateTime.fromJSDate((this.validationObject as Voyage).eta as Date);
      return {
        min: date.toISO(),
        'first-day-of-week': 1
      }
    } else return {
      min: DateTime.now().toISO(),
      'first-day-of-week': 1
    };
  }

  get etaDatePickerProps() {
    return {
      min: DateTime.now().toISO(),
      'first-day-of-week': 1
    }
  }

  get isFormInvalid(): boolean {
    return this.isSubmitted && this.$v.$invalid;
  }

  @Watch('isFormInvalid', {immediate: true})
  private onInvalidChange() {
    this.$emit('is-invalid-change', this.isFormInvalid)
  }

  public async mounted() {
    if (this.harbors.length === 0) {
      try {
        await this.loadHarbors();
      } catch (e) {
        this.$notifyErrorSimplified('SHIP_ARRIVAL.NOTIFICATIONS.ERRORS.NO_HARBORS');
      }
    }
    // Pass component to parent for validation
    this.$emit('mounted', this);

    // Use ship arrival data if available
    this.validationObject = {...this.validationObject, ...this.currentShipArrival.voyage as Voyage};

    if (this.validationObject.nextPorts) {
      this.selectedNextPorts = this.findHarbors(this.validationObject.nextPorts);
    }
    if (this.validationObject.lastPorts) {
      this.selectedLastPorts = this.findHarbors(this.validationObject.lastPorts);
    }
  }

  public beforeDestroy() {
    if (this.currentShipArrival) {
      // Reassign validation values
      // @ts-ignore
      this.setVoyageForCurrentShipArrival(Object.assign(this.currentShipArrival.voyage, this.validationObject));
    }
  }

  public validate(): boolean {
    this.isSubmitted = true;
    this.$v.$touch();
    return !this.$v.$invalid;
  }

  public async nextStepClicked() {
    this.validate();
    if (!this.isFormInvalid) {
      this.$emit('next-step');
    }
  }

  public async previousStepClicked() {
    this.validate();
    if (!this.isFormInvalid) {
      this.$emit('previous-step');
    }
  }

  public onFinish() {
    this.validate();
    if (!this.isFormInvalid) {
      // Update voyage
      // @ts-ignore
      this.setVoyageForCurrentShipArrival(Object.assign(this.currentShipArrival.voyage, this.validationObject));
      this.$emit('finish');
    }
  }

  public etsDateChanged() {
    if (this.validationObject.ets && this.validationObject.eta &&
        this.validationObject.ets < this.validationObject.eta) {
      this.validationObject.eta = '';
      (this.$refs.etaDate as DatetimePicker).clearHandler();
      this.$forceUpdate();
    }
  }

  public etaDateChanged() {
    if (this.validationObject.ets && this.validationObject.eta &&
        this.validationObject.eta > this.validationObject.ets) {
      this.validationObject.ets = '';
      (this.$refs.etsDate as DatetimePicker).clearHandler();
    }
  }

  /**
   * Iterate over the input array, filter the city and assign the string array to the nextPort attribute
   * @param harbors The whole input is emitted, so iterate over the input and filter the city
   * @private
   */
  private selectNextPort(harbors: Harbor[]) {
    this.validationObject.nextPorts = harbors.map((harbor) => {
      return harbor.city;
    });
  }

  /**
   * Iterate over the input array, filter the city and assign the string array to the lastPort attribute
   * @param harbors The whole input is emitted, so iterate over the input and filter the city
   * @private
   */
  private selectLastPort(harbors: Harbor[]) {
    this.validationObject.lastPorts = harbors.map((harbor) => {
      return harbor.city;
    });
  }

  /**
   * Find the harbors in the listArray that are contained in the ports array
   * @param ports The array to intersect
   * @private
   */
  private findHarbors(ports: string[]) {
    return this.getListItems(ports).filter((harbor) => ports.includes(harbor.city));
  }

  /**
   * The Items for the autocompletes. Some elements need to be added to the array, because they were written in a normal
   * textField and may not be in the list of valid harbors
   * @param ports
   * @private
   */
  private getListItems(ports: string[]) {
    const items: Harbor[] = [];
    // Take all harbors from store...
    items.push(...this.harbors);
    ports.forEach((port, index) => {
      if (this.selectedLastPorts.find(harbor => harbor.city === port)) { // Use the csv id from the old data
        items.push(this.selectedLastPorts.find(harbor => harbor.city === port)!);
      } else if (this.selectedNextPorts.find(harbor => harbor.city === port)) {
        items.push(this.selectedNextPorts.find(harbor => harbor.city === port)!);
      } else if (!this.harbors.find((harbor) => harbor.city === port)) {
        // ... and add ports that are written before
        items.push(Harbor.parseFromObject({city: port, csvId: '' + (index + this.harbors.length + 1)}));
      }
    });
    return items;
  }

  /**
   * Filter method, to filter the autocomplete items
   * @param item The item itself
   * @param queryText The written text
   * @param itemText The text of the item, that will be displayed in selection
   * @private
   */
  private filterHarbors(item: Harbor, queryText: string, itemText: string) {
    return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        (item.description && item.description.toLowerCase().indexOf(queryText.toLowerCase()) > -1);
  }
}
